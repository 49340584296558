:root {
  --primary-color: #cacaca;
  --secondary-color: #e3e2e2;
  --text-color: #6A6A6A;
  --hovering-color: rgb(240, 240, 240);
  margin: 0;
  padding: 0;
}


[data-theme='dark']{
  --primary-color: #363A3D;
  --secondary-color: #282B2D;
  --text-color: #E8E6E3;
  --hovering-color: rgb(30, 30, 30);
}

body {
  margin: 0;
  padding: 0;
  /* font-family: cairo, sans-serif; */
  direction: rtl;
}
