@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');



body {
  background-color: var(--primary-color);
  font-family: "Rubik", sans-serif;
  color: var(--text-color);

}


.preview-section {
  background-color: var(--secondary-color);
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  width: 80vw;
  height: 95vh;
}

/* sidebar */
.notes-list {
  list-style: none;
  padding: 0;
  margin-top: 50px;
}

.notes-list .note-item {
  padding: 4px;
  text-align: center;
  margin: 10px;
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.notes-list .note-item:hover,
.active {
  background-color: var(--hovering-color) !important;
  cursor: pointer;
}

.add-btn {
  display: inline-block;
  padding: 4px;
  text-align: center;
  margin: 10px 10px 0;
  background-color: var(--secondary-color);
  color: var(--text-color);
  border-radius: 10px;
  width: 92%;
  cursor: pointer;
  position: relative;
}

.add-btn input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.add-btn span {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.add-btn:hover {
  background-color: var(--hovering-color);
}

.add-btn input[type="checkbox"]:checked+span {
  /* Style for the checked state */
  background-color: var(--checked-color);
  color: var(--checked-text-color);
}

/* note */
.note-title {
  position: relative;
}

.line-under::after {
  content: "";
  padding: 8px;
  display: block;
  width: 100%;
  border-bottom: 2px solid var(--text-color);
  margin-bottom: 25px;
}

.note-operations {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.note-operations a {
  color: var(--text-color);
  padding: 0 15px;
}

.change-theme {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
}


.note-content {
  white-space: wrap;
  word-wrap: break-word;
}

/* adding note */

.form-input {
  width: 100%;
  background-color: var(--primary-color);
  border-radius: 10px;
  padding: 10px;
  color: var(--text-color);
  border: none;
}

.button {
  background-color: var(--primary-color);
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 150px;
}

/* alert */
.alert-container {
  position: fixed;
  left: 5%;
  top: 5%;
  background: #f43a4c;
  border-radius: 5px;
  width: auto;
  padding: 10px;
}

.alert-container ul {
  font-size: 14px;
  color: #fff;
  margin: 0;
  padding-right: 15px;
}

/* responsivity */

@media (max-width: 992px) {
  .preview-section {
    width: 70vw;
  }
}

@media (max-width:576px) {
  html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }


  .preview-section {
    display: flex;
    max-width: 100%;
    width: calc(100% - 20px);
    height: auto;
  }

  .note-operations div {
    text-align: end;

  }
  .preview-section div {
    width: 100%;
  }

  .add-btn {
    width: 100%;
    margin: 5px 0;
  }

  .notes-list {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;

  }

  .notes-list .note-item {
    width: 30%;
    margin: 5px 0;
  }

  .note-title {
    width: 80%;
    white-space: wrap;
    word-wrap: break-word;
  }

  .note-content {
    width: 90%;
    white-space: wrap;
    word-wrap: break-word;
  }
}